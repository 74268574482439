import React, { useState } from "react";
import PropTypes from "prop-types";

const TranslationContext = React.createContext();

export const TranslationProvider = ({ children }) => {
  const [translation, setTranslation] = useState([]);

  return (
    <TranslationContext.Provider value={{ translation, setTranslation }}>
      {children}
    </TranslationContext.Provider>
  );
};

TranslationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TranslationContext;
