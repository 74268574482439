export const COLOR_VIOLET = "violet";
export const COLOR_PURPLE = "purple";
export const COLOR_ORANGE = "orange";
export const COLOR_GREEN = "green";
export const COLOR_BLUE = "blue";

export const COLOR_PRIMARY = "#7cba3a";
export const COLOR_PRIMARY_HOVER = "#3f8b00";
export const COLOR_SECONDARY = "#4c79c7";
export const COLOR_SECONDARY_DISABLED = "#bbcff3";
export const COLOR_TERTIARY = "#908f86";
export const COLOR_LIGHT = "#edeef2";

export const baseColorHex = {
  primary: "115, 153, 50",
  secondary: "23, 66, 141",
  tertiary: "136, 172, 236",
  four: "175, 210, 116",
  five: "126, 135, 163",
  six: "183, 181, 166",
  seven: "219, 221, 225",
  eight: "233, 231, 216",
  nine: "245, 245, 245",
  // violet: "125, 87, 161",
  // purple: "199, 26, 136",
  // orange: "235, 102, 23",
  // green: "68, 167, 31",
  // blue: "0, 140, 214",
};

export const chartColors = [
  "primary",
  "secondary",
  "tertiary",
  // "violet",
  // "purple",
  // "orange",
  // "green",
  // "blue",
];

export const { REACT_APP_AM_CHARTS_LICENCE, REACT_APP_AM_CHARTS_MAPS_LICENCE } = process.env;
